<template>
    <div class="page">
        <van-nav-bar title="企业内刊" left-text="返回" left-arrow @click-left="back"></van-nav-bar>
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
        <van-cell  v-for="item in list" :key="item.id" isLink :to="'/article/list/detail/' + item.id">
            <template #title>
                <div>
                <van-icon name="bookmark-o" /><span style="margin-left:5px;color:#000000;">{{item.name}}</span>
                </div>
                <div>
                <van-icon name="clock-o" /><span style="margin-left:5px;">发布日期: {{item.publishTime}}</span>
                </div>
                <div>
                <span class="introduceLong">{{item.introduce}}</span>
                </div>
            </template>
        </van-cell>
        
        </van-list>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                pageIndex:1,
                pageSize:20,
                bookSrc:require('../../assets/img/book.png'),
            };
        },
        methods: {
            onLoad() {
                console.log('onloaded=======');
                this.$axios.get("/article/getArticleList", {params:{pageIndex:this.pageIndex, pageSize:this.pageSize, status:1}})
                .then(res => {
                    if(res.code == "ok") {
                        this.list = this.list.concat(res.data.records);
                        this.loading = false;
                        if (res.data.pages == 0 || this.pageIndex == res.data.pages) {
                            this.finished = true;
                        } else {
                            this.pageIndex++;
                        }
                    } 
                }).catch(err=> {});
            },
            getList() {
                this.$axios.get("/article/getArticleList", {params:{pageIndex:this.pageIndex, pageSize:this.pageSize, status:1}})
                .then(res => {
                    if(res.code == "ok") {
                        list = list.concat(res.data.records);
                    } 
                }).catch(err=> {});
            },
            back() {
                this.$back();
            },

        },
        mounted() {
            // this.getList();
        }
    };
</script>

<style lang="less" scoped>
    .logout {
        bottom: 0;
        height: 50px;
        color: #ff5f16;
    }

    /* 本页公共样式 */
    .gray {
        color: #797d82;
    }

    .bott0 {
        bottom: 0;
    }

    .orange {
        color: #ff5f16
    }

    .f15 {
        font-size: 15px;
    }

    .bd-gray {
        border-bottom: 1px solid #f5f5f5;
    }

    // 设置
    header {
        .city {
            height: 44px;
            line-height: 44px;
        }
    }

    // 账号ID
    .id {
        height: 49px;
        line-height: 49px;

    }
</style>
<style lang="less">
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
    .introduceLong {
        color: #999;
        width: 300px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
</style>

